<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <h2 class="brand-text text-primary ml-1">
          Phire Studio
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Drcc Report Portal
          </b-card-title>
          <b-card-text class="mb-2">
            Please Register to your account to login
          </b-card-text>

          <!-- form -->
          <validation-observer ref="registerValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- name -->
              <b-form-group
                label="Name"
                label-for="register-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="min:3"
                >
                  <b-form-input
                    id="register-name"
                    v-model="userName"
                    :state="errors.length > 0 ? false:null"
                    name="register-name"
                    placeholder="Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <div>
                <b-form-group
                  v-slot="{ ariaDescribedby }"
                  label="Role"
                >
                  <b-form-radio-group
                    id="radio-group-2"
                    v-model="role"
                    :aria-describedby="ariaDescribedby"
                    name="radio-sub-component"
                    plain
                  >
                    <b-form-radio value="Dealer">
                      Dealer
                    </b-form-radio>
                    <b-form-radio value="Installer">
                      Installer
                    </b-form-radio>
                    <b-form-radio value="Admin">
                      Admin
                    </b-form-radio>
                    <!-- <b-form-radio value="Production">
                      Production
                    </b-form-radio> -->
                  </b-form-radio-group>
                </b-form-group>
              </div>

              <!-- password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="register-password">Password</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="min:6"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="register-password">Confirm Password</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="confirmPassword"
                  rules="min:6|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="confirm-password"
                      v-model="confirm_password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="confirm_password"
                      placeholder="············"
                    />
                    <!-- <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility1"
                      />
                    </b-input-group-append> -->
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="outline-primary"
                block
                @click="registerUser"
              >
                Register
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>Back to Login? </span>
            <b-link :to="{name:'page-login'}">
              <span>&nbsp;Sign In</span>
            </b-link>

          </b-card-text>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userName: '',
      password: '',
      userEmail: '',
      confirm_password: '',
      role: 'Admin',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      successMessage: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    registerUser() {
      this.$store
        .dispatch('auth/register', {
          email: this.userEmail,
          password: this.password,
          role: this.role,
          name: this.userName,
        })
        .then(() => {
          this.$bvToast.toast("New account has been successfully registered.", {
            title: 'Success',
            variant: 'success',
            solid: true,
          })
          this.userName = ''
          this.password = ''
          this.userEmail = ''
          this.confirm_password = ''
          this.userName = ''
          this.role = ''
          // this.successMessage = true
        })
        .catch(error => {
          // console.log(error)
          this.$bvToast.toast(error.response.data.errors[0].message, {
            title: 'Failed',
            variant: 'danger',
            solid: true,
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
